<template>
  <div class="MapContainer">
    <div class="MapSwitch">
      <div @click="toggle" :class="{ active: !weekend }">
        <span v-if="lang == ''">Weekdays</span>
        <span v-else>Semaine</span>
      </div>
      <div @click="toggle" :class="{ active: weekend }">
        <span v-if="lang == ''">Weekend</span>
        <span v-else>Week-end</span>
      </div>
    </div>

    <div class="MapImage">
      <picture :class="{ active: !weekend }" v-if="!weekend">
        <source
          srcset="/map/weekdays_landscape2800.jpg"
          media="(min-width: 1600px) and (max-width:10000px)"
          type="image/jpeg"
        />
        <source
          srcset="/map/weekdays_landscape1600.jpg"
          media="(min-width: 800px) and (max-width:1600px)"
          type="image/jpeg"
        />
        <source
          srcset="/map/weekdays_landscape800.jpg"
          media="(min-width: 600px) and (max-width:800px)"
          type="image/jpeg"
        />
        <source
          srcset="/map/weekdays_portrait800.jpg"
          media="(min-width: 150px) and (max-width: 600px)"
          type="image/jpeg"
        />
        <img src="/map/weekdays_portrait400.jpg" loading="lazy" class="image" />
      </picture>

      <picture :class="{ active: weekend }" v-else>
        <source
          srcset="/map/weekend_landscape2800.jpg"
          media="(min-width: 1600px) and (max-width:10000px)"
          type="image/jpeg"
        />
        <source
          srcset="/map/weekend_landscape1600.jpg"
          media="(min-width: 800px) and (max-width:1600px)"
          type="image/jpeg"
        />
        <source
          srcset="/map/weekend_landscape800.jpg"
          media="(min-width: 600px) and (max-width:800px)"
          type="image/jpeg"
        />
        <source
          srcset="/map/weekend_landscape800.jpg"
          media="(min-width: 600px)"
          type="image/jpeg"
        />
        <source
          srcset="/map/weekend_portrait800.jpg"
          media="(min-width: 150px) and (max-width: 600px)"
          type="image/jpeg"
        />
        <img src="/map/weekend_portrait400.jpg" loading="lazy" class="image" />
      </picture>
    </div>

    <a
      href="/downloads/ADT_MAP.pdf"
      target="top"
      class="MapDL"
      title="Download PDF"
      >Download PDF</a
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      weekend: false,
    };
  },
  props: {
    lang: false,
  },
  methods: {
    toggle() {
      this.weekend = !this.weekend;
    },
  },
};
</script>

<style lang="css" scoped>
.MapContainer {
  position: relative;
  display: block;
}

.MapImage {
  display: block;
  margin-bottom: 12px;
}

.MapImage picture {
  opacity: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 1s;
}

.MapImage picture.active {
  opacity: 1;
}

.MapImage .image {
  display: block;
  width: 100%;
}

.MapSwitch {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.MapSwitch div {
  background: white;
  border: 1px solid #222929;
  padding: 5px 7px;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
  pointer-events: auto;
  user-select: none;

  text-align: center;
}

.MapSwitch div.active {
  background: #222929;
  color: white;
}

.MapDL {
  display: block;
  position: relative;
  border: 1px solid black;
  background: white;
  padding: 5px 7px;
  width: calc(100% - 14px - 2px);
  transition: background 0.5s;
}

.MapDL:hover {
  background: #222929;
  color: white;
}

@media only screen and (min-width: 600px) {
  .MapDL {
    position: absolute;
    display: inline-block;
    width: auto;
    bottom: 0.5em;
    left: 0.5em;
  }

  .MapSwitch div {
    background: white;
    border: 1px solid black;
    padding: 9px 7px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9rem;
  }

  .MapContainer {
    margin-left: calc(-100vw / 12 * 5 + 12px);
  }
}

@media only screen and (min-width: 768px) {
  .MapDL {
    bottom: 1em;
    left: 1em;
  }

  .MapContainer {
    margin-left: calc(-100vw / 12 * 4 + 12px);
    margin-bottom: 4em;
  }
}

@media only screen and (min-width: 900px) {
  .MapContainer {
    margin-top: -2em;
    margin-left: calc(-100vw / 12 * 4 + 20px);
    margin-bottom: 4em;
  }
}
</style>
