<template lang="html">
  <div v-if="pageList" class="SideBar Grid">
    <Title @switchLang="switchLang" :lang="lang" />
    <aside :class="{ showSmall: pageData.options.content }">
      <ul>
        <li
          v-for="question in pageList"
          :key="question.id"
          v-if="question.is_locked == 0 || !question.is_locked"
        >
          <router-link
            v-if="pageData.options.content"
            :to="{
              name: pageData.options.link,
              params: { slug: question.slug },
            }"
          >
            {{ returnQuestion(question.id) }}
          </router-link>
          <span
            v-else
            title="Jump to Question"
            @click="toElement(question.id)"
            v-html="returnQuestion(question.id)"
          />
        </li>
      </ul>
    </aside>

    <article>
      <Content
        :content="pageData.content"
        v-if="pageData.options.showContent"
        :class="{ hideBorder: pageData.options.content }"
      />
      <details
        v-for="question in pageList"
        :open="openDetails"
        :key="question.id"
        v-if="pageData.options.showItems"
      >
        <summary :ref="'question' + question.id" v-if="question.is_top == 0">
          {{ returnQuestion(question.id) }}
        </summary>

        <Content
          :content="question['content' + lang]"
          v-if="question.is_top == 0"
        />

        <Map v-if="question.id == 60" :lang="lang" />

        <Container
          v-if="question._container"
          v-for="container in question._container"
          :key="container.id"
          :container="container"
          :images="question._images"
        />

        <summary :ref="'question' + question.id" v-if="question.is_top == 1">
          {{ returnQuestion(question.id) }}
        </summary>

        <Content
          :content="question['content' + lang]"
          v-if="question.is_top == 1"
        />
      </details>
    </article>
  </div>
</template>

<script>
import stylesSidebar from "../assets/css/sidebar.css";
import Title from "@/components/Elements/Title.vue";
import Content from "@/components/Elements/Content.vue";
import Container from "@/components/Container/Container.vue";
import Map from "@/components/Map.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Title,
    Content,
    Container,
    Map,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      id: null,
      lang: "",
    };
  },
  computed: {
    ...mapGetters(["pageData", "pageList"]),
    openDetails() {
      if (this.windowWidth < 600) return false;
      else return true;
    },
  },
  methods: {
    ...mapActions(["fetchItems"]),
    returnQuestion(id) {
      return this.pageList.filter((question) => question.id == id)[0][
        "title" + this.lang
      ];
    },
    toElement(id) {
      this.id = id;
      const html = document.getElementsByTagName("html");
      html[0].style.scrollBehavior = "smooth";
      setTimeout(this.scroll, 100);
    },
    scroll() {
      const element = this.$refs["question" + this.id][0];
      const pos = element.getBoundingClientRect().top + window.scrollY - 60;
      window.scrollTo(0, pos);
      setTimeout(this.resetScrollBehavior, 500);
    },
    resetScrollBehavior() {
      const html = document.getElementsByTagName("html");
      html[0].style.scrollBehavior = "auto";
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    switchLang() {
      if (this.lang == "") this.lang = "_fr";
      else this.lang = "";
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.fetchItems({
      table: this.pageData.options.items.t,
      target: "setPageList",
      query: this.pageData.options.items.query,
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
